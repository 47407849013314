import React from 'react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

function index({ value, handleChange }) {
	return (
		<CKEditor
			editor={Editor}
			data={value}
			config={{
				simpleUpload: {
					uploadUrl: `${process.env.REACT_APP_API_URL}images`,
					withCredentials: true,
				},
			}}
			onChange={handleChange}
		/>
	)
}

export default index
