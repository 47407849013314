import React from 'react'
import CheckRole from '../../components/CheckRole'
import { Checkbox } from '../../components/FormComponents'
import { H2 } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import useRoles from '../../hooks/useRoles'
import usePermissions from '../../hooks/usePermissions'
import useForms from '../../hooks/useForms'
import useStates from '../../hooks/useStates'

function UserRolesPermissions({ user, handleRoleSelect }) {
	const { l } = useLocale()
	const { roles } = useRoles(false)
	const { permissions } = usePermissions()
	const { forms } = useForms({ paginate: false })
	const { states } = useStates(false)

	return (
		<div>
			<CheckRole has='edit_user_roles'>
				<H2>{l('roles')}</H2>
				{roles.map(role => (
					<React.Fragment key={role._id}>
						<Checkbox
							name={role._id}
							checked={user.roles.includes(role._id)}
							onChange={handleRoleSelect}
							label={role.name}
						/>
					</React.Fragment>
				))}
			</CheckRole>
			<CheckRole has='edit_user_permissions'>
				<H2>{l('permissions')}</H2>
				<div className='columns-3'>
					{Object.keys(permissions).map(key => {
						const group = permissions[key]
						return (
							<React.Fragment key={key}>
								<p className='font-bold'>{l(key)}</p>
								{group.map(permission => (
									<React.Fragment key={permission._id}>
										<Checkbox
											name={permission._id}
											checked={user.permissions.includes(permission._id)}
											onChange={e => handleRoleSelect(e, 'permissions')}
											label={permission.name}
										/>
									</React.Fragment>
								))}
							</React.Fragment>
						)
					})}
				</div>
			</CheckRole>
			<CheckRole has='edit_user_form_permissions'>
				<H2>{l('form_permissions')}</H2>
				<div className='grid grid-cols-3'>
					{forms.map(form => (
						<React.Fragment key={form._id}>
							<Checkbox
								name={form._id}
								checked={user.forms.includes(form._id)}
								label={form.name}
								onChange={e => handleRoleSelect(e, 'forms')}
							/>
						</React.Fragment>
					))}
				</div>
			</CheckRole>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
				<CheckRole has='edit_user_derive_permissions'>
					<div>
						<H2>{l('derive_state_permissions')}</H2>
						<div className='grid grid-cols-3'>
							{states.map(state => (
								<React.Fragment key={state._id}>
									<Checkbox
										name={state._id}
										checked={user.states.includes(state._id)}
										label={state.name}
										onChange={e => handleRoleSelect(e, 'states')}
									/>
								</React.Fragment>
							))}
						</div>
					</div>
				</CheckRole>
				<CheckRole has='edit_user_view_permissions'>
					<div>
						<H2>{l('view_state_permissions')}</H2>
						<div className='grid grid-cols-3'>
							{states.map(state => (
								<React.Fragment key={state._id}>
									<Checkbox
										name={state._id}
										id={`view_${state._id}`}
										checked={user.viewStates.includes(state._id)}
										label={state.name}
										onChange={e => handleRoleSelect(e, 'viewStates')}
									/>
								</React.Fragment>
							))}
						</div>
					</div>
				</CheckRole>
			</div>
		</div>
	)
}

export default UserRolesPermissions
