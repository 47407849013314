import {
  Datetime,
  InputField,
  Multiple,
  Radios,
  Section,
  TextArea,
  EditorField,
  Select,
  TagField,
  ImageGalleryField,
  PredefinedQuestionsField,
  TitleField,
  FileField,
} from '../components/AnswerFormComponents'

const componentsMap = {
  section: Section,
  textarea: TextArea,
  editor: EditorField,
  radios: Radios,
  multiple: Multiple,
  input: InputField,
  datetime: Datetime,
  select: Select,
  tag: TagField,
  image_gallery_item: ImageGalleryField,
  predefined_questions: PredefinedQuestionsField,
  title: TitleField,
  file: FileField
}

export default componentsMap
