import React from 'react'
import {H3, PageHeading} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import CheckRole from '../../components/CheckRole'
import useClinicCase from '../../hooks/useClinicCase'
import CheckFormPermission from '../../components/CheckFormPermission'
import {textColor} from '../../utils'
import ViewClinicCase from '../../components/ViewClinicCase'
import EditClinicCase from '../../components/EditClinicCase'
import Button from '../../components/Button'
import {Select} from '../../components/FormComponents'
import Divider from '../../components/Divider'
import Modal from '../../components/Modal'
import ReassignClinicCaseModal from '../../components/ReassignClinicCaseModal'
import BooksToClinicCase from '../../components/BooksToClinicCase'

function ShowClinicCase () {
  const {l} = useLocale()
  const {
    id,
    clinicCase,
    execute,
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    commentsRef,
    errors,
    download,
    canEdit,
    canDownload,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setClinicCase,
    isGamificationEnabled,
    canAssignDifficulties,
    canAssignQualities,
    handleChange,
    reassign,
    reassignModal
  } = useClinicCase()

  return (
    <>
      <PageHeading
        title={`${l('clinic_case')} ${id}`}
        image={'/img/clinic_case.svg'}
      >
        {canDownload && (
          <CheckRole has='download_clinic_cases'>
            <Button onClick={download}>{l('download')}</Button>
          </CheckRole>
        )}
        <CheckRole has="reassign_clinic_cases">
          <Button onClick={reassignModal.openModal} type="warning">{l('reassign_case')}</Button>
        </CheckRole>
        <BooksToClinicCase caseId={id} />
      </PageHeading>
      <Card>
        {clinicCase.state?.editable === true && canEdit ? (
          <EditClinicCase
            clinicCase={clinicCase}
            handleCheckboxChange={handleCheckboxChange}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            errors={errors}
            handleAddRow={handleAddRow}
            handleDeleteRow={handleDeleteRow}
            handleTagChange={handleTagChange}
            setClinicCase={setClinicCase}
          />
        ) : (
          <ViewClinicCase clinicCase={clinicCase} />
        )}
        {isGamificationEnabled && (
          <>
            <Divider />
            <H3>{l('assign_quality_and_difficulty')}</H3>
            <div className='flex gap-3'>
              {canAssignDifficulties ? (
                <CheckRole has="assign_clinic_cases_difficulty">
                  <Select label={l('assign_difficulty')} name="difficulty" value={clinicCase.difficulty?._id} onChange={handleChange} options={clinicCase.form?.config.gamification.difficulties} valueKey='_id' labelKey='{label} ({points} pts.)' useLabelRegex />
                </CheckRole>
              ) : <p><strong>{l('difficulty')}:</strong> {clinicCase.difficulty?.label} ({clinicCase.difficulty?.points} pts)</p>}
              {canAssignQualities ? (
                <CheckRole has="assign_clinic_cases_quality">
                  <Select label={l('assign_quality')} name="quality" onChange={handleChange} value={clinicCase.quality?._id} options={clinicCase.form?.config.gamification.qualities} valueKey='_id' labelKey='{label} ({points} pts.)' useLabelRegex />
                </CheckRole>
              ) : <p><strong>{l('quality')}:</strong> {clinicCase.quality?.label} ({clinicCase.quality?.points} pts)</p>}
            </div>
          </>
        )}
        <CheckRole has='execute_actions_on_clinic_cases'>
          <CheckFormPermission has={clinicCase.form?._id}>
            <Divider />
            <div className='flex gap-3 mb-3'>
              {clinicCase?.allowedStates?.map(action => {
                const color = textColor(action.color)
                return (
                  <button
                    key={action._id}
                    style={{backgroundColor: action.color, color}}
                    className={`px-2 py-1 rounded-md ease-in-out duration-200`}
                    onClick={() => execute(action._id)}
                  >
                    {action.label}
                  </button>
                )
              })}
            </div>
          </CheckFormPermission>
        </CheckRole>
        {clinicCase.state?.canAddComments && (
          <CheckRole has='add_clinic_cases_comments'>
            <Divider />
            <label className='font-bold'>{l('comments')}</label>
            <textarea
              ref={commentsRef}
              className='w-full rounded-md text-black px-3 py-2'
              rows='5'
            ></textarea>
          </CheckRole>
        )}
        <CheckRole has='view_clinic_cases_comments'>
          <Divider />
          <H3>{l('historic_comments')}</H3>
          {clinicCase.comments?.map(comment => (
            <div className='px-2 py-1 my-2 border rounded-md'>
              <p className='font-bold text-xl mb-1'>
                {l('comment')} {comment._id}
              </p>
              <div className='grid grid-cols-2 md:grid-cols-4'>
                <p>
                  <strong>{l('user')}:</strong> {comment.user.name}
                </p>
                <p>
                  <strong>{l('source')}:</strong>{' '}
                  <span
                    className='py-0.5 px-2 rounded-full'
                    style={{
                      background: comment.source.color,
                      color: textColor(comment.source.color),
                    }}
                  >
                    {comment.source.name}
                  </span>
                </p>
                <p>
                  <strong>{l('destination')}:</strong>{' '}
                  <span
                    className='py-0.5 px-2 rounded-full'
                    style={{
                      background: comment.destination.color,
                      color: textColor(comment.destination.color),
                    }}
                  >
                    {comment.destination.name}
                  </span>
                </p>
                <p>
                  <strong>{l('date')}:</strong>{' '}
                  {new Date(comment.createdAt).toLocaleString()}
                </p>
              </div>
              <p className='font-bold mt-2'>{l('comment')}:</p>
              <p className='whitespace-pre-line'>{comment.comment}</p>
            </div>
          ))}
        </CheckRole>
      </Card>

      <Modal show={reassignModal.showModal} onClose={reassignModal.closeModal}>
        <ReassignClinicCaseModal reassign={reassign} />
      </Modal>
    </>
  )
}

export default ShowClinicCase
