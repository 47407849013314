import React from "react";
import useLocale from "../../hooks/useLocale";
import Button from "../Button";
import Code from "../Code";

function QuestionsButton({ handleViewQuestions }) {
  const { l } = useLocale();

  return (
    <Button Button type="warning" onClick={handleViewQuestions}>
      {l("view_questions")}
    </Button>
  );
}

function ShowElement({
  itemsKeys,
  item,
  errors,
  index,
  componentsMap,
  handleSubInputChange,
  handleSubCheckboxChange,
  handleSubTagChange,
  handleViewQuestions,
  handleSubFileSelect,
  handleSubMetaChange,
  handleAddGalleryItem,
  files,
  handleDeleteFile,
  handleSetMetaField,
  handleSetConsentDocument,
  handlePushGalleryFile
}) {
  const { l } = useLocale();

  return (
    <React.Fragment>
      {itemsKeys.map((key) => {
        const element = item[key];
        const error = errors?.find(
          (error) =>
            (error.key === element.attributes?.label ||
              error.key === element._id) &&
            error.passed === false,
        );
        if (!(element.component in componentsMap)) {
          return <Code code={element} />;
        }

        return (
          <React.Fragment key={`${index}${key}`}>
            {React.createElement(componentsMap[element.component], {
              item: element,
              handleInputChange: handleSubInputChange,
              handleCheckboxChange: handleSubCheckboxChange,
              handleTagChange: handleSubTagChange,
              handleFileSelect: handleSubFileSelect,
              handleMetaChange: handleSubMetaChange,
              index: key,
              handleAddGalleryItem,
              files,
              handleSetConsentDocument,
              handleDeleteFile,
              handlePushGalleryFile,
              handleSetMetaField,
              Component: element.attributes?.has_questions ? (
                <QuestionsButton
                  handleViewQuestions={() =>
                    handleViewQuestions(element, index, key)
                  }
                />
              ) : null,
              ErrorComponent:
                error &&
                error.message.map((message) => (
                  <p className="text-sm text-red-500">{l(message)}</p>
                )),
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default ShowElement;
