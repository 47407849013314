import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ClinicCase from '../models/ClinicCase'
import { toast } from 'react-toastify'
import useLocale from '../hooks/useLocale'
import AppContext from '../contexts/AppContext'
import useModal from './useModal'

function useClinicCase () {
  const { l } = useLocale()
  const { id } = useParams()
  const [clinicCase, setClinicCase] = React.useState({ items: {} })
  const [errors, setEerrors] = React.useState([])
  const [temps, setTemps] = React.useState([])
  const commentsRef = React.useRef(null)
  const navigate = useNavigate()
  const { permissions } = React.useContext(AppContext)
  const reassignModal = useModal()

  function handleChange (e) {
    const { name, value } = e.currentTarget
    setClinicCase(old => ({
      ...old,
      [name]: value
    }))
  }

  function reassign (userId) {
    ClinicCase.reassign(userId, id).then(res => {
      toast.success(l('case_reassigned'))
      reassignModal.closeModal()
    }).catch(err => toast.error(l('unhandled_error')))
  }

  function getClinicCase () {
    ClinicCase.show(id)
      .then(res => {
        for (const key in res.items) {
          const item = res.items[key]
          if (
            item.component === 'section' &&
            item.attributes.is_multiple === true &&
            Array.isArray(item.items)
          ) {
            const itemTemp = JSON.parse(JSON.stringify(res.items[key].items[0]))
            Object.keys(itemTemp).forEach(key => {
              itemTemp[key].attributes.value =
                itemTemp[key].component !== 'multiple' && itemTemp[key].component !== 'tag' ? '' : []
            })
            setTemps({
              ...temps,
              [key]: itemTemp,
            })
          }
        }
        setClinicCase(res)
      })
      .catch(err => {
        if (ClinicCase.fetch.status === 403) {
          return navigate('/clinic-cases')
        }
        console.log(err)
        toast.error(l('general_error'))
      })
  }

  React.useEffect(() => {
    getClinicCase()
  }, [])

  const handleInputChange = (e, key, subKey = null, secondKey = null) => {
    const { value } = e.target
    const aux = clinicCase
    if (subKey === null) aux.items[key].attributes.value = value
    else if (secondKey === null && subKey !== null) aux.items[key].items[subKey].attributes.value = value
    else aux.items[key].items[secondKey][subKey].attributes.value = value
    setClinicCase({ ...aux })
  }

  const handleCheckboxChange = (e, key, subKey = null, secondKey = null) => {
    const { value } = e.target
    const aux = clinicCase
    if (subKey === null) {
      const index = clinicCase.items[key].attributes.value.indexOf(value)
      if (index === -1) aux.items[key].attributes.value.push(value)
      else aux.items[key].attributes.value.splice(index, 1)
    } else {
      const index =
        clinicCase.items[key].items[secondKey][subKey].attributes.value.indexOf(value)
      if (index === -1)
        aux.items[key].items[secondKey][subKey].attributes.value.push(value)
      else aux.items[key].items[secondKey][subKey].attributes.value.splice(index, 1)
    }

    setClinicCase({ ...aux })
  }

  const handleSubmit = e => {
    e.preventDefault()
    ClinicCase.update({ id, data: clinicCase })
      .then(res => toast.success(l('clinic_case_edited')))
      .catch(err => toast.error(l('general_error')))
  }

  function execute (action) {
    ClinicCase.executeAction({
      id,
      action,
      comment: commentsRef?.current?.value,
      items: clinicCase.items,
      quality: clinicCase.quality,
      difficulty: clinicCase.difficulty
    })
      .then(res => {
        toast.success(l('clinic_case_drifted'))
        navigate('/clinic-cases')
      })
      .catch(err => {
        if (ClinicCase.fetch.status === 422) {
          toast.error(l('fields_required'))
          return setEerrors(err.errors)
        }
        toast.error(err.message)
      })
  }

  function download () {
    ClinicCase.download(id).then(blob => {
      var a = document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = `${clinicCase.form.name}_${clinicCase.user.name}`
      a.click()
    })
  }

  const handleAddRow = key => {
    const aux = clinicCase
    aux.items[key].items.push(JSON.parse(JSON.stringify(temps[key])))
    setClinicCase({ ...aux })
  }

  const handleDeleteRow = (key, arrIndex) => {
    if (clinicCase.items[key].items.length === 1) return
    const aux = clinicCase
    aux.items[key].items.splice(arrIndex, 1)
    setClinicCase({ ...aux })
  }

  const handleTagChange = (value, key, subKey = null, multipleIndex = null) => {
    const aux = clinicCase
    if (subKey === null) aux.items[key].attributes.value = value
    else if (multipleIndex !== null)
      aux.items[key].items[multipleIndex][subKey].attributes.value = value
    else aux.items[key].items[subKey].attributes.value = value
    setClinicCase({ ...aux })
  }

  return {
    id,
    clinicCase,
    execute,
    handleSubmit,
    handleInputChange,
    handleCheckboxChange,
    commentsRef,
    errors,
    download,
    canEdit: permissions.some(
      permission => permission.name === 'edit_clinic_cases'
    ),
    canDownload: clinicCase.state?.canDownload,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setClinicCase,
    isGamificationEnabled: clinicCase.form?.config.gamification.enabled,
    canAssignDifficulties: clinicCase.state?.canAssignDifficulty,
    canAssignQualities: clinicCase.state?.canAssignQuality,
    handleChange,
    reassign,
    reassignModal
  }
}

export default useClinicCase
