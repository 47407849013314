import React from 'react'
import componentsMap from '../../config/answerComponents'
import useLocale from '../../hooks/useLocale'
import Button from '../Button'
import useQuestions from '../../hooks/useQuestions'
import FormQuestions from '../FormQuestions'

function EditClinicCase ({
  clinicCase,
  handleInputChange,
  handleCheckboxChange,
  handleSubmit,
  errors,
  handleAddRow,
  handleDeleteRow,
  handleTagChange,
  setClinicCase,
}) {
  const items = Object.keys(clinicCase.items)
  const { l } = useLocale()
  const {
    handleViewQuestions,
    ...restOfQuestionsLogic
  } = useQuestions({ clinicCase, setClinicCase })

  return (
    <form onSubmit={handleSubmit} className='mb-5'>
      <div className='grid grid-cols-12 gap-3'>
        {items.map(key => {
          const item = clinicCase.items[key]
          const error = errors.find(
            error =>
              (error.key === item.attributes?.label || error.key === item._id) && error.passed === false
          )
          return (
            <div style={{ gridColumn: `span ${item.attributes.cols || 12} / span ${item.attributes.cols || 12}` }} key={key}>
              {React.createElement(componentsMap[item.component], {
                item,
                handleInputChange,
                handleCheckboxChange,
                index: key,
                errors,
                handleAddRow,
                handleDeleteRow,
                handleTagChange,
                handleViewQuestions,
                ...restOfQuestionsLogic,
              })}
              {error && error.message.map(message => <p className='text-sm text-red-500'>{l(message)}</p>)}
              {item.attributes.has_questions && (
                <Button isSubmit={false} type='warning' onClick={() => handleViewQuestions(item, key)}>
                  {l('view_questions')}
                </Button>
              )}
            </div>
          )
        })}
      </div>

      <FormQuestions {...restOfQuestionsLogic} />
      {/* <CheckRole has='edit_clinic_cases'>
				<Button>{l('save')}</Button>
			</CheckRole> */}
    </form>
  )
}

export default EditClinicCase
